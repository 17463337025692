import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// react components for routing our app without refresh
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
// @material-ui/icons
// core components

import Header from "components/Header/Header.jsx"
import Footer from "components/Footer/Footer.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

import Parallax from "components/Parallax/Parallax.jsx"
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx"

import componentsStyle from "assets/jss/material-kit-react/views/components.jsx"
import SEO from "../../components/SEO"
class Gallery extends React.Component {
  render() {
    const { classes, ...rest } = this.props
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedSquare,
      classes.imgFluid
    )
    return (
      <div>
        <SEO />
        <Header
          brand="West Coast Beach Volleyball Club"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white",
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/caldyboyle.jpeg")}>
          <div className={classes.container}>
            <GridContainer></GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
        <GridContainer
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <GridItem sm={6}>
              <h2>Upcoming UKBT Competitions</h2>
                <p>Entry for the UKBT event on the 29th and 30th June 2024 closed on Tuesday 25th June 2024.</p>
                <br/>
                <p>Tournament details and the draw will be emailed to entrants in due course.</p>

              </GridItem>
          </GridContainer>
          <br />
          <GridContainer
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <GridItem sm={6}>
              <h2>Club Calendar</h2>

              <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                <iframe
                  // eslint-disable-next-line max-len
                  src="https://calendar.google.com/calendar/embed?src=c_6m43nonkj2os10rvg4r6sqk3ps%40group.calendar.google.com&ctz=Europe%2FLondon"
                  style={{ border: 0 }}
                  width="100%"
                  height="600"
                  frameBorder="0"
                  scrolling="no"
                  title="club calender"
                ></iframe>
              </GridItem>
            </GridItem>
          </GridContainer>
          <br />
          <br />
        </div>
        <Footer />
      </div>
    )
  }
}

export default withStyles(componentsStyle)(Gallery)
